import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import { shortQueries } from "../../../queries";
import Intro from "./Intro";
import PanZoom from "./PanZoom";
import TourContainer from "../TourContainer";

const components = [Intro, PanZoom];

const WelcomeTour = (props) => {
  const [stepCount, setStepCount] = useState(1);
  const { onClose, selectedValue, open } = props;
  const short = useMediaQuery(json2mq(shortQueries));

  const handleClose = () => {
    onClose(selectedValue);
  };

  const Component = components[stepCount - 1];

  return (
    <TourContainer
      handleClose={handleClose}
      open={open}
      onButtonClick={() =>
        components.length === stepCount
          ? handleClose()
          : setStepCount(stepCount + 1)
      }
      buttonText={components.length === stepCount ? "Start" : "Continue"}
    >
      <Component short={short} />
    </TourContainer>
  );
};

export default WelcomeTour;
