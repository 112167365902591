import { Box, DialogTitle, Typography } from "@mui/material";
import React from "react";

const Intro = ({ short }) => {
  return (
    <>
      <DialogTitle sx={{ px: 2, py: 0, pb: 1, fontWeight: "bold" }}>
        Estimate your lawn
      </DialogTitle>
      <Box sx={{ px: 2, pb: 1 }}>
        <Box sx={{ textAlign: "center" }}>
          <img
            src={short ? "/measur.it-intro-small.png" : "/measur.it-intro.png"}
            alt="measureit-welcome"
            style={short ? { width: 200 } : { width: 250 }}
          />
        </Box>

        <Typography
          sx={{
            py: short ? 0 : 1,
          }}
          style={short ? { width: 225, fontSize: 12 } : { fontSize: 14 }}
        >
          <span style={{ fontWeight: "bold", color: "#1565C0" }}>
            Measur.it
          </span>{" "}
          is an easy way to measure online.
          <br />
          <br />
          If you can use a paint brush, you can measure your lawn.
        </Typography>
      </Box>
    </>
  );
};

export default Intro;
