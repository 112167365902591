import React from 'react';

// Import React Google Maps
// https://www.npmjs.com/package/react-google-maps
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";

/*
  This component renders an interactive Google map using React Google Maps.

  This component takes the following props:

  * mapRef ... the ref of the Google Map; exposes API methods
  * setMapRef ... sets the Google Map ref
  * googleMapURL ... a valid Google Maps URL (which includes an API key)
  * loadingElement, containerElement, mapElement ... options for React Canvas Draw
    https://www.npmjs.com/package/react-canvas-draw
  * mapCenter ... a lat/lng object to use as the center of the map
  * getCenterData ... a helper function which determines the center of the map
  * zoomLevel ... a numeric zoom level to use for the map
  * onZoomChanged ... a callback function used when zoom level changes on the map
*/

const MapComponent = withScriptjs(withGoogleMap(
  (props) => {

    return (
      <GoogleMap
        ref={ (ref) => { props.setMapRef(ref) } }
        defaultZoom={props.zoomLevel}
        defaultCenter={props.mapCenter}
        mapTypeId="hybrid"
        onCenterChanged={()=>{ 
          props.getCenterData();
        }}
        tilt={0}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          zoomControl: false,
          gestureHandling:"greedy"
        }}
        zoom={props.zoomLevel}
        onZoomChanged={props.onZoomChanged}
        className="map-view"
      >
      </GoogleMap>
    );
  }
))

export default MapComponent;
