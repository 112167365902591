import React, { Fragment, useState, useEffect } from 'react';

/*
  This component renders a map image with a drawable HTML canvas.

  This component takes the following props:

  * mode ... a string value; valid selections include "area" and "perimeter"
  * canvasRef ... the ref of the HTML canvas; exposes API methods
  * setCanvasRef ... sets the HTML canvas ref
  * mapImageURL ... a valid static Google Map image URL (which includes an API key)
  * updateCanvasLines ... a callback function which adds the current point data
    to the array of canvas line data; takes the pointData array as an argument
  * getCoveragePercentage ... a callback function called when drawing ends
  * pixelDistance ... total pixel distance recorded for linear distance measurement
  * getDistanceDrawn ... a callback function called when drawing ends
  * brushRadius ... current numeric radius of drawing brush
  * paintColor ... Hex value of brush paint color
  * eraseMode ... a boolean value; determines use of eraser mode
  * mapWidth ... numeric width of map image
  * mapHeight ... numeric height of map image
*/

export default function Draw(props) {

  // Set initial state
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentPixelDistance, setCurrentPixelDistance] = useState(0);
  const [pointData, setPointData] = useState([]);

  // Acquire ref of canvas upon load and add event listeners
  useEffect(()=>{
    // Get canvas reference; set to state
    const drawCanvas = document.getElementById("drawCanvas");
    props.setCanvasRef(drawCanvas);
    // Build preventDefault function for touch events
    function preventDefaultTouchEvent(event) {
      if (event.target === drawCanvas) {
        event.preventDefault();
      }
    }
    // Build options object for event listeners
    // IMPORTANT: Passive is set to true by default by Google Chrome;
    // these options are vital for appropriate use of touch controls
    const eventListenerOptions = {
      once: false,
      passive: false
    };
    // Add event listeners
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("touchstart", preventDefaultTouchEvent, eventListenerOptions);
    document.addEventListener("touchmove", preventDefaultTouchEvent, eventListenerOptions);
    // Remove event listeners upon unmount
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleTouchEnd);
      document.removeEventListener("touchstart", preventDefaultTouchEvent);
      document.removeEventListener("touchmove", preventDefaultTouchEvent);
    };
  }, []);

  // Update data when drawing is complete
  useEffect(()=>{
    if (!isDrawing && props.canvasRef) {
      props.updateCanvasLines(pointData);
      setPointData([]);
      if (props.mode === "area") { props.getCoveragePercentage() }
      if (props.mode === "perimeter") {
        props.getDistanceDrawn(props.pixelDistance + currentPixelDistance);
        setCurrentPixelDistance(0);
      }
    }
  }, [isDrawing]);
  // Build functions for handling canvas drawing
  const drawPoint = (location) => {
    // Get context from canvas
    const ctx = props.canvasRef.getContext("2d");
    // Print dot on the canvas based on location
    ctx.fillStyle = props.paintColor;
    ctx.beginPath();
    ctx.globalCompositeOperation = (props.eraseMode ? "destination-out" : "source-over");
    ctx.arc(location.x, location.y, props.brushRadius, 0, Math.PI * 2, true);
    ctx.fill();
    ctx.restore();
  }

  const drawLine = (location) => {
    // Get context from canvas
    const ctx = props.canvasRef.getContext("2d");
    // Acquire former drawing location
    const previousLocation = currentLocation;
    if (props.mode === "perimeter") {
      // Determine distance of movement
      const xDistance = location.x - previousLocation.x;
      const yDistance = location.y - previousLocation.y;
      // biome-ignore lint/style/useExponentiationOperator: <explanation>
      const newDistance = Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
      // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
      if (!isNaN(newDistance)) {
        setCurrentPixelDistance((prevState) => (prevState + newDistance));
      }   
    }
    // Draw line
    ctx.beginPath();
    ctx.globalCompositeOperation = (props.eraseMode ? "destination-out" : "source-over");
    ctx.strokeStyle = props.paintColor;
    ctx.moveTo(previousLocation.x, previousLocation.y);
    ctx.lineTo(location.x, location.y);
    ctx.lineWidth = props.brushRadius * 2;
    ctx.stroke();
    ctx.closePath();
  }

  const draw = (location) => {
    // If drawing is false, render an initial shape,
    // then start drawing process
    // Otherwise, draw a line w/ending shape
    if (!isDrawing) {
      drawPoint(location);
      // Save location
      setCurrentLocation(location);
      // Set isDrawing to true
      setIsDrawing(true);
    } else {
      drawPoint(location);
      drawLine(location);
      // Save location
      setCurrentLocation(location);
    }
    // Save current location to pointData
    setPointData([...pointData, location]);
  }

  // Build handlers for interactions
  const onDrawStart = (event, eventType) => {
    // Draw only if canvas is loaded
    if (!props.canvasRef) {
      return;
    }
      // Get location of event
      const location = getEventCoordinates(event, eventType);
      // Draw point
      draw(location);
  }

  const onDrawMove = (event, eventType) => {
    // If not drawing, do nothing
    if (!isDrawing) {
      return;
    }
      // Get location of event
      const location = getEventCoordinates(event, eventType);
      // Draw line
      draw(location);
  }

  const onDrawEnd = () => {
    // Set drawing to false and reset data
    setIsDrawing(false);
    setCurrentLocation(null);
  }

  const handleMouseDown = (event) => {
    // Only fire onDrawStart if left mouse button is clicked
    if (event.button !== 0) {
      return;
    }
      onDrawStart(event, "mouse");
  }

  const handleTouchStart = (event) => {
    onDrawStart(event, "touch");
  }

  const handleMouseMove = (event) => {
    onDrawMove(event, "mouse");
  }

  const handleTouchMove = (event) => {
    onDrawMove(event, "touch");
  }

  const handleMouseUp = () => {
    onDrawEnd();
  }

  const handleTouchEnd = () => {
    onDrawEnd();
  }

  // Build helper function for determining location of interaction events
  // Takes an event as an argument and returns an object { x, y }
  // Also takes a second string argument: "mouse" || "touch"
  const getEventCoordinates = (event, eventType) => {
    const canvasRect = props.canvasRef.getBoundingClientRect();
    let x;
    let y;
    if (eventType === "mouse") {
      x = event.clientX - canvasRect.left;
      y = event.clientY - canvasRect.top;
    } else if (eventType === "touch") {
      x = event.touches[0].clientX - canvasRect.left;
      y = event.touches[0].clientY - canvasRect.top;
    } else {
      console.log("Event type undefined.");
    }
    return { x, y };
  }

  return (
    <Fragment>
      <img
        src={props.mapImageURL}
        width={props.mapWidth}
        height={props.mapHeight}
        alt="Google Maps"
        style={{
          position: "absolute",
          top: 0,
          left: 0
        }}
      />
      <canvas
        id="drawCanvas"
        width={props.mapWidth}
        height={props.mapHeight}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        style={{ position: "absolute", top: 0, left: 0, opacity: 0.6, }}
        className="draw-tools-image"
      >
      </canvas>
    </Fragment>
  );

}