import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
    
    const handleClose = () => {
      props.setWarningOpen(false);
    };

    const proceedEstimate = () => {
      props.proceedEstimate()
    }
  
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{fontWeight:'bold'}} id="alert-dialog-title">{"Oops! Does your estimate look right?"}</DialogTitle>
          <DialogContent>
            <img src="/mobilerightwrong2.png" alt=""  style={{width: '78%'}}/> 
            <DialogContentText id="alert-dialog-description" style={{fontSize: 15}}>
              We will only service the areas that are <strong>COLORED IN</strong> and not just outlined. Your <strong>COLORED IN</strong> image is saved and we will only service <strong>COLORED IN</strong> areas on the picture
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button 
            onClick={proceedEstimate}
            color="primary"
            variant="contained"
            sx={{ backgroundColor: "#1565C0" }}
           >
              Submit Estimate
            </Button>
            <Button 
              onClick={handleClose}
              color="primary"
              variant="contained"
              sx={{ backgroundColor: "#1565C0" }}
              autoFocus>
              Continue Coloring
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    );
  }