import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/system";

const TourContainer = ({
  handleClose,
  open,
  children,
  onButtonClick,
  buttonText,
}) => {
  return (
    <Dialog open={open}>
      <Box sx={{ maxWidth: 400 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <img
              src="/Measureit-logo.png"
              alt="measureit-welcome"
              style={{ height: 30 }}
            />
          </Box>

          <Box sx={{ flex: 1, textAlign: "right", p: 0 }}>
            <Button color="inherit" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Box>
        </Box>

        {children}

        <Box sx={{ textAlign: "right", p: 2, pt: 0 }}>
          <Button
            sx={{ backgroundColor: "#1565C0" }}
            color="primary"
            variant="contained"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TourContainer;
