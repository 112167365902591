import { Box } from "@mui/material";
import React from "react";

const GoodAndBadEstimation = ({ short }) => {
  return (
    <>
      <Box sx={{ px: 2, pb: 0 }}>
        <Box sx={{ textAlign: "center" }}>
          {short ? null : (
            <img
              src="/mobilerightwrong2.png"
              alt="animate pan zoom"
              style={{ width: 300 }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default GoodAndBadEstimation;
