import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faCircle } from "@fortawesome/free-solid-svg-icons";
import UndoIcon from "@mui/icons-material/Undo";
import { Backdrop, Box, Divider, Fab, Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export default function AreaDrawControls(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const drawCanvas = document.getElementById("drawCanvas");

  useEffect(() => {
    if (drawCanvas) {
      drawCanvas.className = `${drawCanvas.className} cursor-medium`;
    }
    console.log(drawCanvas?.className);
  }, [drawCanvas]);

  const [showSize, setShowSize] = useState(false);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const removeClass = useCallback(() => {
    if (drawCanvas) {
      drawCanvas.classList.remove("cursor-large");
      drawCanvas.classList.remove("cursor-medium");
      drawCanvas.classList.remove("cursor-small");
    }
  }, [drawCanvas]);

  const onSmallChange = useCallback(() => {
    if (drawCanvas) {
      removeClass();
      drawCanvas.className = `${drawCanvas.className} cursor-small`;
    }

    props.setBrushRadius(props.brushSize.small);
    setAnchorEl(null);
    setShowSize(false);
  }, [drawCanvas, props, removeClass]);

  const onMediumChange = useCallback(() => {
    if (drawCanvas) {
      removeClass();
      drawCanvas.className = `${drawCanvas.className} cursor-medium`;
    }

    props.setBrushRadius(props.brushSize.medium);
    setAnchorEl(null);
    setShowSize(false);
  }, [drawCanvas, props, removeClass]);

  const onLargeChange = useCallback(() => {
    if (drawCanvas) {
      removeClass();
      drawCanvas.className = `${drawCanvas.className} cursor-large`;
    }

    props.setBrushRadius(props.brushSize.large);
    setAnchorEl(null);
    setShowSize(false);
  }, [drawCanvas, props, removeClass]);

  const handleClose = () => {
    if (!showSize) {
      setAnchorEl(null);
    }
  };

  return (
    <Fragment>
      <Backdrop
        sx={{ zIndex: "100" }}
        onClick={() => {
          setShowSize(false);
          setAnchorEl(null);
        }}
        open={open}
      />
      <Fab
        sx={{
          position: "fixed",
          bottom: 70,
          right: 10,
          background:"#1565C0",
          color: "white",
        }}
        onClick={handleOpen}
      >
        <EditIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
      >
        {!showSize ? (
          <>

            <MenuItem
              sx={{ width: '200px' }}
              onClick={() => {
                handleClose();
                props.setEraseMode(false);
              }}
            >
              <EditIcon
                sx={{
                  color: !props.eraseMode
                    ? props.color.iconActive
                    : props.color.iconInactive,
                  marginRight: "15px",
                }}
              />
              Marker
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                props.setEraseMode(true);
              }}
            >
              <FontAwesomeIcon
                icon={faEraser}
                style={{
                  color: props.eraseMode
                    ? props.color.iconActive
                    : props.color.iconInactive,
                  marginRight: "25px",
                }}
              />
              Eraser
            </MenuItem>

            <Divider />
            <MenuItem
              onClick={() => {
                setShowSize(true);
              }}
            >
              <FontAwesomeIcon
                icon={faCircle}
                style={{
                  color: props.color.iconInactive,
                  marginRight: "25px",
                }}
              />
              Size
              <ArrowRightIcon
                style={{
                  color: props.color.iconInactive,
                  marginLeft: "auto",
                }}
              />
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={() => {
                props.handleUndoButton();
                handleClose();
              }}
            >
              <UndoIcon
                sx={{
                  color: props.color.iconInactive,
                  marginRight: "25px",
                }}
              />
              Undo
            </MenuItem>

            <MenuItem
              onClick={() => {
                props.handleClearButton();
                handleClose();
              }}
            >
              <DeleteIcon
                sx={{
                  color: props.color.iconInactive,
                  marginRight: "25px",
                }}
              />
              Clear All
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={onLargeChange}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  fontSize={props.brushSize.large}
                  color={
                    props.brushRadius === props.brushSize.large
                      ? props.color.iconActive
                      : props.color.iconInactive
                  }
                  style={{
                    marginRight: "25px",
                  }}
                />
              </Box>

              Large
            </MenuItem>
            <MenuItem
              sx={{ width: '200px' }}
              onClick={onMediumChange}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  fontSize={props.brushSize.medium}
                  color={
                    props.brushRadius === props.brushSize.medium
                      ? props.color.iconActive
                      : props.color.iconInactive
                  }
                  style={{
                    marginRight: "25px",
                  }}
                />
              </Box>
              Medium
            </MenuItem>
            <MenuItem
              width='200px'
              onClick={onSmallChange}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25px' }}>
                <FontAwesomeIcon
                  icon={faCircle}
                  fontSize={props.brushSize.small}
                  color={
                    props.brushRadius === props.brushSize.small
                      ? props.color.iconActive
                      : props.color.iconInactive
                  }
                  style={{
                    marginRight: "25px",
                  }}
                />
              </Box>
              Small
            </MenuItem>
          </>
        )}
      </Menu>
    </Fragment>
  );
}
