import React, { Fragment, useState, useEffect } from "react";

// Import geolib for GPS measurement
// https://www.npmjs.com/package/geolib
import { getDistance } from "geolib";

// Import Map components
import MapComponent from "./components/map.js";
import MapControls from "./components/mapControls.js";

// Import draw components
import Draw from "./components/draw.js";
import AreaDrawControls from "./components/areaDrawControls.js";
import PerimeterDrawControls from "./components/perimeterDrawControls.js";
import { GetQuoteButton } from "./components/getQuoteButton.js";
import AlertDialog from "./components/alert";

import * as _ from "lodash";
/*
  This component renders the estimator view.
  The view flips between a Google Maps view and a drawable
  canvas which uses a static Google Maps image as a background.
  The purpose of the canvas is either to determine the square
  footage of coverage or the approximate linear footage of the
  lines drawn.

  As a "dumb" component, this component is dependent on data and
  callbacks being passed in from a parent component:

  * mode ... a string value; valid selections include "area" and "perimeter"
  * GOOGLE_API_KEY ... a valid Google API key with access to
    the Google Maps JavaScript API and Google Maps Static API
  * mapHeight ... the numeric pixel height value of the map and image
  * mapWidth ... the numeric pixel width value of the map and image
  * style ... an optional object of styles to be applied to the estimator container
  * mapCenter ... a lat/lng object to use as the center of the map
  * updateMapCenter ... a callback function; takes LatLng object as an argument
  * startingZoomLevel ... a numeric zoom level to use for the map
  * updateMapBounds ... a callback function; takes an object argument as follows:
    { sw: LatLng Object, ne: LatLng Object }
  * mapDistances ... an object which includes height/width of map in linear feet
  * color ... an object of color values (optional)
  * brushSize ... an object of numeric brush size values (optional)
  * updateCoverageSquareArea ... a callback function; takes a numerical value as an argument
  * updateDrawnLinearDistance ... a callback function; takes a numerical value as an argument
*/

const Estimator = (props) => {
  const { setDrawTourOpen } = props;
  const adjustmentPercentage = 0.18;
  const mapHeightAdjusted = Number.parseInt(
    props.mapHeight - props.mapHeight * adjustmentPercentage
  );
  // Build values for optional parameters
  let color = {
    paint: "#2BE396",
    iconActive: "#1565C0",
    iconInactive: "#757575",
    button: "whitesmoke",
  };

  let brushSize = {
    tiny: 3,
    small: 6,
    medium: 12,
    large: 18,
  };

  if (props.color) {
    color = props.color;
  }

  if (props.brushSize) {
    brushSize = props.brushSize;
  }

  // Set initial state
  const [zoomLevel, setZoomLevel] = useState(props.startingZoomLevel || 20);
  const [drawMode, setDrawMode] = useState(false);
  const [mapRef, setMapRef] = useState(null); // the ref of the Google Map; exposes API methods
  const [mapImageURL, setMapImageURL] = useState(null); // location of static Google Map image
  const [mapImageData, setMapImageData] = useState(null);
  const [mapDistances, setMapDistances] = useState(null);
  const [pixelDistance, setPixelDistance] = useState(0);
  const [distancePerPixel, setDistancePerPixel] = useState(0);
  const [canvasRef, setCanvasRef] = useState(null); // the ref of the HTML canvas; exposes API methods
  const [canvasLines, setCanvasLines] = useState([]);
  const [eraseMode, setEraseMode] = useState(false);
  const [brushRadius, setBrushRadius] = useState(0);

  const [warningOpen, setWarningOpen] = useState(false);

  // Build Google Maps URL for interactive map
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${props.GOOGLE_API_KEY}&amp;callback=initMap&amp;libraries=geometry`;

  // GOOGLE MAP-RELATED FUNCTIONS

  // Extract center data from Google Maps API
  // https://developers.google.com/maps/documentation/javascript/reference/map#Map.getCenter
  // Updates state with new LatLng object
  const getCenterData = () => {
    const centerData = mapRef.getCenter();
    const centerLat = centerData.lat();
    const centerLng = centerData.lng();
    props.updateMapCenter({
      lat: centerLat,
      lng: centerLng,
    });
  };

  // Extract bounds data from Google Maps API
  // https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
  // Returns an object with lat/lng data for sw and ne corners
  const getBoundsData = () => {
    const boundsData = mapRef.getBounds();
    const sw = boundsData.getSouthWest();
    const ne = boundsData.getNorthEast();
    // console.log("Bounds data received: ", boundsData);

    // Return object
    return {
      sw: { lat: sw.lat(), lng: sw.lng() },
      ne: { lat: ne.lat(), lng: ne.lng() },
    };
  };

  // Calculate physical distances from boundary data using geolib
  // Takes boundary coordinates object as argument;
  // returns object with height/width values in feet
  const calculateMapDistances = (data) => {
    const { sw, ne } = data;
    const nw = { lat: ne.lat, lng: sw.lng };
    // Get distance from nw corner to sw corner
    const mapHeightDistance = getDistance(
      {
        latitude: nw.lat,
        longitude: nw.lng,
      },
      {
        latitude: sw.lat,
        longitude: sw.lng,
      }
    );
    const mapWidthDistance = getDistance(
      {
        latitude: nw.lat,
        longitude: nw.lng,
      },
      {
        latitude: ne.lat,
        longitude: ne.lng,
      }
    );

    // console.log(`Approximate Map Height Distance: ${mapHeightDistance * 3.28} feet`);
    // console.log(`Approximate Map Width Distance: ${mapWidthDistance * 3.28} feet`);
    // console.log(`Approximate Map Diagonal Distance: ${mapDiagonalDistance * 3.28} feet`);
    // // Convert meters to feet and return
    return {
      height: mapHeightDistance * 3.28,
      width: mapWidthDistance * 3.28,
    };
  };

  // Build function to fetch image based on current state of map
  const getImage = () => {
    const fetchUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${props.mapCenter.lat},${props.mapCenter.lng}&zoom=${zoomLevel}&size=${props.mapWidth}x${mapHeightAdjusted}&maptype=satellite&key=${props.GOOGLE_API_KEY}`;
    console.log(fetchUrl);

    fetch(fetchUrl)
      .then((response) => {
        // If response is 200, return URL of image; otherwise return false
        if (response.status === 200) {
          // console.log("Here's the URL to the image:");
          // console.log(response.url);
          setMapImageURL(response.url);
        } else {
          // TODO: Likely a callback function should fire in the event of failure.
          console.log("Something went wrong:");
          console.log(response);
        }
      })
      .catch((error) => {
        // TODO: Likely a callback function should fire in the event of failure.
        console.error(error);
      });
  };

  // Build handlers for zoom controls
  const handleZoomInButton = () => {
    // Zoom level cannot be greater than 21
    // TODO: actual max zoom level is determined by
    // Google Maps API; this should likely be checked and
    // added to the estimator
    // https://developers.google.com/maps/documentation/javascript/maxzoom

    if (zoomLevel < 20) {
      const newZoomLevel = zoomLevel + 1;
      setZoomLevel(newZoomLevel);
      // console.log("Zoom level updated to:", newZoomLevel);
    } else {
      setBrushRadius(brushSize.small);
      // console.log("Cannot zoom in any further.");
    }
  };

  const handleZoomOutButton = () => {
    // Zoom level cannot be less than 0
    if (zoomLevel > 0) {
      const newZoomLevel = zoomLevel - 1;
      setZoomLevel(newZoomLevel);
      // console.log("Zoom level updated to:", newZoomLevel);
    } else {
      console.log("Cannot zoom out any further.");
    }
  };

  const onZoomChanged = () => {
    // Verify that the current zoom level
    // and the zoom level saved in state are the same
    // If they are not, fix this

    const currentZoomLevel = mapRef.getZoom();
    console.log("current zoom level", currentZoomLevel);

    if (zoomLevel !== currentZoomLevel) {
      setZoomLevel(currentZoomLevel);
      // console.log("Zoom level updated to:", currentZoomLevel);
    }
  };

  // Build handler for lock map button
  const handleLockMapButton = () => {
    // Get lat/lng of map corners
    const mapBoundaries = getBoundsData();
    // Get map distances
    const distances = calculateMapDistances(mapBoundaries);
    setMapDistances(distances);
    // Determine feet per pixel
    setDistancePerPixel(distances.width / props.mapWidth);
    // Send data to callback functions
    props.updateMapBounds(mapBoundaries);
    // Fetch static image from Google Maps API;
    // drawMode will fire upon receipt
    setDrawTourOpen(true);
    getImage();
  };

  // HTML CANVAS-RELATED FUNCTIONS

  // Build listener for triggering draw mode
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (mapImageURL) {
      getBase64Image(mapImageURL).then((imagedata) => {
        setMapImageData(imagedata);
      });

      if (!drawMode) {
        let defaultBrushRadius = brushSize.medium;
        if (zoomLevel < 20) {
          defaultBrushRadius = brushSize.small;
        }

        if (props.mode === "perimeter") {
          defaultBrushRadius = brushSize.tiny;
        }
        setBrushRadius(defaultBrushRadius);
        setDrawMode(true);
      }
    }
  }, [mapImageURL]);


  // Build function to update canvas lines
  // Takes an array of location objects as an argument
  // Array represents current line information
  const updateCanvasLines = (pointData) => {
    const newLines = [...canvasLines];
    // Build new line object
    let brushMode = "draw";
    if (eraseMode) {
      brushMode = "erase";
    }
    const newLine = {
      pointData,
      brushMode,
      brushRadius,
    };
    // Update canvas lines
    newLines.push(newLine);
    setCanvasLines(newLines);
    // console.log("Current lines:", newLines);
  };

  // Build function to determine percentage of canvas coverage
  const getCoveragePercentage = () => {
    // Acquire drawing data from canvas
    // https://www.w3schools.com/tags/canvas_getimagedata.asp
    const ctx = canvasRef.getContext("2d");
    const drawingData = ctx.getImageData(
      0,
      0,
      props.mapWidth,
      mapHeightAdjusted
    );
    // console.log(drawingData);
    // Iterate over drawing data and determine coverage
    // Data is an array of values; every four values represents one pixel
    // Pixel data is an rgba value; each value is 0 - 255
    let coveragePixelNumber = 0;
    const canvasPixelData = [];
    for (let i = 0; i < drawingData.data.length; i += 4) {
      if (
        drawingData.data[i] > 0 ||
        drawingData.data[i + 1] > 0 ||
        drawingData.data[i + 2] > 0 ||
        drawingData.data[i + 3] > 0
      ) {
        coveragePixelNumber++;
      }
      // Convert alpha layer to tenths (versus 0 - 255)
      const alphaValue = Math.round(drawingData.data[i + 3] * 0.3921) / 100;
      // Dump canvas pixel data
      canvasPixelData.push({
        r: drawingData.data[i],
        g: drawingData.data[i + 1],
        b: drawingData.data[i + 2],
        a: alphaValue,
      });
    }
    const totalPixelNumber = mapHeightAdjusted * props.mapWidth;
    const totalSquareAreaOfMapImage = mapDistances.height * mapDistances.width;
    const coveragePercentage = coveragePixelNumber / totalPixelNumber;
    const coverageSquareArea = totalSquareAreaOfMapImage * coveragePercentage;

    // console.log("Here is the pixel data: ", canvasPixelData);
    // console.log("Total pixel data in canvas: ", (drawingData.data.length/4));
    // console.log("Total number of pixels: ", totalPixelNumber);
    // console.log("Number of covered pixels: ", coveragePixelNumber);
    // Send data to callback functions
    props.updateCoverageSquareArea(coverageSquareArea);
  };

  // Build function to determine total length of distance drawn
  // Takes a numeric pixel distance value as an argument
  // Sends the result to callback function and updates
  // current value of total pixel distance drawn
  const getDistanceDrawn = (value) => {
    // Convert pixel distance to linear feet
    const linearDistance = value * distancePerPixel;
    // Send to callback function
    props.updateDrawnLinearDistance(linearDistance);
    // Update state
    setPixelDistance(value);
  };

  // Build function to render HTML canvas based on line input
  // Takes the target canvas context an argument, as well as
  // an array of canvas line objects with pointData, brushMode, and brushRadius keys
  // Will return the total pixel distance of lines drawn
  const renderCanvasLines = (ctx, lineData) => {
    // Prep line distance
    let totalPixelDistance = 0;
    // Iterate over canvas line history and redraw canvas
    for (const line of lineData) {
      for (let i = 0; i < line.pointData.length; i++) {
        let operation = "source-over";
        if (line.brushMode === "erase") {
          operation = "destination-out";
        }
        const location = line.pointData[i];
        // Print initial dot
        ctx.fillStyle = color.paint;
        ctx.beginPath();
        ctx.globalCompositeOperation = operation;
        ctx.arc(location.x, location.y, line.brushRadius, 0, Math.PI * 2, true);
        ctx.fill();
        ctx.restore();
        if (i > 0) {
          const previousLocation = line.pointData[i - 1];
          if (props.mode === "perimeter") {
            // Determine distance of movement
            const xDistance = location.x - previousLocation.x;
            const yDistance = location.y - previousLocation.y;
            const newDistance = Math.sqrt(
              (xDistance ** 2) + (yDistance ** 2)
            );
            // biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
            if (!isNaN(pixelDistance)) {
              totalPixelDistance = totalPixelDistance + newDistance;
            }
          }
          // Draw line
          ctx.beginPath();
          ctx.globalCompositeOperation = operation;
          ctx.strokeStyle = color.paint;
          ctx.moveTo(previousLocation.x, previousLocation.y);
          ctx.lineTo(location.x, location.y);
          ctx.lineWidth = line.brushRadius * 2;
          ctx.stroke();
          ctx.closePath();
        }
      }
    };
    return totalPixelDistance;
  };

  // Build handler for unlock map button
  const handleUnlockMapButton = () => {
    // Confirm with user; if false; do nothing
    if (
      window.confirm("Abandon your work and go back to the interactive map?")
    ) {
      // Set draw mode to false
      setDrawMode(false);
      // Reset data
      setMapImageURL(null);
      setMapDistances(null);
      setPixelDistance(0);
      setDistancePerPixel(0);
      setCanvasRef(null);
      setCanvasLines([]);
      // Update callback functions
      props.updateMapBounds(null);
      props.updateCoverageSquareArea(0);
      props.updateDrawnLinearDistance(0);
    } else {
      console.log("Unlock canceled.");
    }
  };

  // Utility method to get base64 image data
  const getBase64Image = (url) => {
    return new Promise((res, rej) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/jpeg", 1.0);
        res(dataURL);
      };

      img.src = url;
    });
  };

  // Build handler for draw canvas clear button
  const handleClearButton = () => {
    // Acquire drawing data from canvas
    // https://www.w3schools.com/tags/canvas_getimagedata.asp
    const ctx = canvasRef.getContext("2d");
    const drawingData = ctx.getImageData(
      0,
      0,
      props.mapWidth,
      mapHeightAdjusted
    );
    console.log(drawingData);
    // Iterate over drawing data and wipe out values
    for (let i = 0; i < drawingData.data.length; i++) {
      drawingData.data[i] = 0;
    }
    // Update canvas data
    ctx.putImageData(drawingData, 0, 0);
    // Update callback functions
    props.updateCoverageSquareArea(0);
    props.updateDrawnLinearDistance(0);
    // Wipe out line history
    setPixelDistance(0);
    setCanvasLines([]);
    console.log("Canvas cleared.");
  };

  // Build handler for undo button
  const handleUndoButton = () => {
    // If no history exists, do nothing
    if (canvasLines.length === 0) {
      console.log("No drawing history to undo!");
    } else {
      // Remove last line from canvasLines
      const newLines = [...canvasLines];
      newLines.pop();
      // Wipe canvas
      // Acquire drawing data from canvas
      // https://www.w3schools.com/tags/canvas_getimagedata.asp
      const ctx = canvasRef.getContext("2d");
      const drawingData = ctx.getImageData(
        0,
        0,
        props.mapWidth,
        mapHeightAdjusted
      );
      // Iterate over drawing data and wipe out values
      for (let i = 0; i < drawingData.data.length; i++) {
        drawingData.data[i] = 0;
      }
      // Update canvas data
      ctx.putImageData(drawingData, 0, 0);
      // Render lines onto HTML canvas and acquire total line distance in pixels
      const totalPixelDistance = renderCanvasLines(ctx, newLines);
      // Update current data based on canvas state
      if (props.mode === "area") {
        getCoveragePercentage();
      }
      if (props.mode === "perimeter") {
        getDistanceDrawn(totalPixelDistance);
      }
      // Update canvas lines in state
      setCanvasLines(newLines);
    }
  };

  // proceed with estimate

  const proceed = () => {
    setWarningOpen(false);
    handleSaveButton(true);
  };

  // Build handler for save button
  const handleSaveButton = (override = false) => {
    // Create new canvas
    const newCanvas = document.createElement("canvas");
    const context = newCanvas.getContext("2d");

    // Set dimensions
    newCanvas.width = canvasRef.width;
    newCanvas.height = canvasRef.height;


    // Acquire background image
    const canvasImage = new Image();
    canvasImage.src = mapImageURL;
    // Set cross origin
    canvasImage.crossOrigin = "anonymous";
    canvasImage.onload = () => {
      // Draw map image to canvas (Background)
      context.drawImage(canvasImage, 0, 0, newCanvas.width, newCanvas.height);

      // Set global alpha to 60%
      context.globalAlpha = 0.6;

      // Draw points/lines from previous canvas to new canvas at 60% opacity
      context.drawImage(canvasRef, 0, 0, newCanvas.width, newCanvas.height);

      // Reset global alpha to 100% for any further drawings
      context.globalAlpha = 1.0;

      // Get image data
      const imageData = context.getImageData(
        0,
        0,
        props.mapWidth,
        mapHeightAdjusted
      );
      const data = imageData.data;

      let tracker = {};
      for (let i = 0; i < data.length; i += 4) {
        const row = Math.floor(i / 4 / props.mapWidth);
        const key = `row${row}`;
        if (!tracker[key]) {
          tracker[key] = 0;
        }


        let r = 0;
        let g = 0;
        let b = 0;
        r = data[i]; // red
        g = data[i + 1]; // green
        b = data[i + 2]; // blue
        // const a = data[i + 3] || 0; // alpha

        if (r === 43 && g === 227 && b === 150) {
          tracker[key]++;
        }
      }

      let covered = 0;
      let nocovered = 0;
      for (const i in tracker) {
        tracker[i] = tracker[i] / brushRadius;
        if (tracker[i] > 0) {
          covered++;
        } else {
          nocovered++;
        }
      }

      const coveredper = covered / (covered + nocovered);

      tracker = _.pickBy(tracker, (value, key) => {
        return value > 0;
      });
      const objs = Object.values(tracker);
      const max = _.max(objs);
      const tolerance = zoomLevel >= 20 ? 0.7 : 0.75;
      let numberOfMax = 0;
      let numberOfMin = 0;
      for (const p of objs) {
        const maxTolerance = (1 - tolerance) * max;
        if (p >= maxTolerance) {
          numberOfMax++;
        } else {
          numberOfMin++;
        }
      };

      const coveredThreshold = zoomLevel >= 20 ? 0.4 : 0.3;
      const ratio = Math.abs((numberOfMax - numberOfMin) / numberOfMax);

      if (override === true) {
        const drawingData = newCanvas.toDataURL("image/jpeg", 1.0);
        props.saveDrawing(drawingData, mapImageData);
      } else {
        if (
          numberOfMin > numberOfMax ||
          coveredper < coveredThreshold ||
          ratio < 0.3
        ) {
          setWarningOpen(true);
          return false;
        }
        const drawingData = newCanvas.toDataURL("image/jpeg", 1.0);
        props.saveDrawing(drawingData, mapImageData);
      }
    };
  };

  // BUILD USER INTERFACE

  // Build interactive map mode
  const mapView = (
    <Fragment>
      <div
        style={{
          position: "relative",
          height: mapHeightAdjusted,
          width: props.mapWidth,
        }}
      >
        <MapControls
          color={color}
          handleZoomInButton={handleZoomInButton}
          handleZoomOutButton={handleZoomOutButton}
          handleLockMapButton={handleLockMapButton}
        />
        <MapComponent
          mapRef={mapRef}
          setMapRef={setMapRef}
          googleMapURL={googleMapURL}
          // TODO: Likely should build a branded loading element of some kind
          loadingElement={
            <div className="mapLoadingElement" style={{ height: "100%" }} />
          }
          containerElement={
            <div
              className="mapContainer"
              style={{
                height: `${mapHeightAdjusted}px`,
                width: `${props.mapWidth}px`,
              }}
            />
          }
          mapElement={<div className="mapElement" style={{ height: "100%" }} />}
          mapCenter={props.mapCenter}
          getCenterData={getCenterData}
          zoomLevel={zoomLevel}
          onZoomChanged={onZoomChanged}
        />
      </div>
    </Fragment>
  );

  // Build drawing modes
  let drawView = null;

  if (props.mode === "area") {
    drawView = (
      <Fragment>
        <div
          style={{
            position: "relative",
            height: mapHeightAdjusted,
            width: props.mapWidth,
          }}
        >
          <AreaDrawControls
            color={color}
            canvasRef={canvasRef}
            brushSize={brushSize}
            brushRadius={brushRadius}
            eraseMode={eraseMode}
            setEraseMode={setEraseMode}
            setBrushRadius={setBrushRadius}
            handleUndoButton={handleUndoButton}
            handleClearButton={handleClearButton}
            handleUnlockMapButton={handleUnlockMapButton}
            handleSaveButton={handleSaveButton}
            measurement={props.measurement}
            mode={props.mode}
          />
          <Draw
            mode={props.mode}
            canvasRef={canvasRef}
            setCanvasRef={setCanvasRef}
            mapImageURL={mapImageURL}
            updateCanvasLines={updateCanvasLines}
            getCoveragePercentage={getCoveragePercentage}
            brushRadius={brushRadius}
            paintColor={color.paint}
            eraseMode={eraseMode}
            mapWidth={props.mapWidth}
            mapHeight={mapHeightAdjusted}
          />
          <GetQuoteButton
            handleSaveButton={handleSaveButton}
            measurement={props.measurement}
            mode={props.mode}
          />
        </div>
      </Fragment>
    );
  }

  if (props.mode === "perimeter") {
    drawView = (
      <Fragment>
        <div
          style={{
            position: "relative",
            height: mapHeightAdjusted,
            width: props.mapWidth,
          }}
        >
          <PerimeterDrawControls
            color={color}
            handleUndoButton={handleUndoButton}
            handleClearButton={handleClearButton}
            handleUnlockMapButton={handleUnlockMapButton}
            handleSaveButton={handleSaveButton}
          />
          <Draw
            mode={props.mode}
            canvasRef={canvasRef}
            setCanvasRef={setCanvasRef}
            mapImageURL={mapImageURL}
            updateCanvasLines={updateCanvasLines}
            pixelDistance={pixelDistance}
            getDistanceDrawn={getDistanceDrawn}
            brushRadius={brushRadius}
            paintColor={color.paint}
            eraseMode={eraseMode}
            mapWidth={props.mapWidth}
            mapHeight={mapHeightAdjusted}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <>
      <div
        className="Estimator"
        style={{
          ...props.style,
          height: mapHeightAdjusted,
          width: props.mapWidth,
        }}
      >
        {drawMode ? drawView : mapView}
        {/* For testing purposes only */}
        {/* {true ? null : props.mode === "area" ? (
          <button onClick={() => props.updateMode("perimeter")}>
            Measure Perimeter
          </button>
        ) : (
          <button onClick={() => props.updateMode("area")}>Measure Area</button>
        )} */}
      </div>
      <AlertDialog
        open={warningOpen}
        setWarningOpen={setWarningOpen}
        proceedEstimate={proceed}
      />
    </>
  );
};

export default Estimator;
